<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="text-center">{{ $store.getters['languageMixin/getStringFromLanguage']('French Section') }}</h5>
      <div class="row">
        <div class="col-md-12 col-sm-12">


          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="fr.name"
                rules="required"
                name="The Name"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Name')"
                          name="name"
                          @keyup="generateSomeFields"
                          fou
                          v-model="formData.name">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="fr.url"
                rules="required"
                name="The URL"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('URL')"
                          name="url"
                          fou
                          v-model="formData.url">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="fr.description"
                rules=""
                name="The Description"
                v-slot="{ passed, failed,errors }">
                <fg-text type="text"
                         :error="failed ? errors[0]: null"
                         :label="$store.getters['languageMixin/getStringFromLanguage']('Description')"
                         name="description"
                         fou
                         v-model="formData.description">
                </fg-text>
              </ValidationProvider>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="fr.cost"
                rules="required"
                name="The Cost"
                v-slot="{ passed, failed,errors }">
                <fg-input type="number"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Cost')"
                          name="cost"
                          fou
                          v-model="formData.cost">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>

          <!--          Category-->
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="fr.category_id"
                rules="required"
                name="The Category"
                v-slot="{ passed, failed,errors }">
                <fg-select
                  name="category_id"
                  size="large"
                  filterable
                  clearable
                  @change="changeCategory"
                  :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Category')"
                  :error="failed ? errors[0]: null"
                  :input-classes="'select-default'"
                  :label="$store.getters['languageMixin/getStringFromLanguage']('Category')"
                  :list="builderData.categoryList"
                  :listItemLabel="'name'"
                  :listItemValue="'id'"
                  v-model="formData.category_id">
                </fg-select>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="fr.training_sessions"
                rules="required"
                name="The Training Sessions"
                v-slot="{ passed, failed,errors }">
                <fg-select
                  name="training_sessions"
                  size="large"
                  filterable
                  clearable
                  multiple
                  :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Training Sessions')"
                  :error="failed ? errors[0]: null"
                  :input-classes="'select-default'"
                  :label="$store.getters['languageMixin/getStringFromLanguage']('Training Sessions')"
                  :list="builderData.trainingSessionList"
                  :listItemLabel="'title'"
                  :listItemValue="'id'"
                  v-model="formData.training_sessions">
                </fg-select>
              </ValidationProvider>
            </div>
          </div>
          <div class="form-group pt-4">
            <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Pay In Installments') }}</label>&nbsp;
            <l-switch v-model="formData.pay_by_installments">
              <i class="fa fa-check" slot="on"></i>
              <i class="fa fa-times" slot="off"></i>
            </l-switch>
          </div>
          <div v-if="formData.pay_by_installments" class="row">
            <div class="col-md-3">
              <ValidationProvider
                vid="fr.first_installment_value"
                rules="required"
                name="The First installment Value"
                v-slot="{ passed, failed,errors }">
                <fg-input type="number"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('First installment value')"
                          name="cost"
                          fou
                          v-model="formData.first_installment_value">
                </fg-input>
              </ValidationProvider>
            </div>
            <div class="col-md-3">
              <ValidationProvider
                vid="fr.second_installment_value"
                rules="required"
                name="The Second installment Value"
                v-slot="{ passed, failed,errors }">
                <fg-input type="number"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Second installment value (a month before the session starts)')"
                          name="cost"
                          fou
                          v-model="formData.second_installment_value">
                </fg-input>
              </ValidationProvider>
            </div>
            <div class="col-md-3">
              <ValidationProvider
                vid="fr.installment_amount"
                rules="required"
                name="The Installment Amount Per Month Value"
                v-slot="{ passed, failed,errors }">
                <fg-input type="number"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Monthly payment')"
                          name="cost"
                          fou
                          v-model="formData.installment_amount">
                </fg-input>
              </ValidationProvider>
            </div>
            <div v-if="checkInstallmentsLabel">
              <div>{{ $store.getters['languageMixin/getStringFromLanguage']('First Installment') }} : <span style="color: #EC6459;">{{checkInstallmentsLabel.first_installment}}</span></div>
              <div>{{ $store.getters['languageMixin/getStringFromLanguage']('Last Installment') }} : <span style="color: #EC6459;">{{checkInstallmentsLabel.last_installment}}</span></div>
            </div>
          </div>



          </div>

          <div class="form-group pt-4">
            <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Is Active') }}</label>&nbsp;
            <l-switch v-model="formData.is_active">
              <i class="fa fa-check" slot="on"></i>
              <i class="fa fa-times" slot="off"></i>
            </l-switch>
          </div>
        <div class="form-group pt-4">
            <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Hide The Cost') }}</label>&nbsp;
            <l-switch v-model="formData.hide_cost">
              <i class="fa fa-check" slot="on"></i>
              <i class="fa fa-times" slot="off"></i>
            </l-switch>
          </div>

      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import { extend } from "vee-validate";
  import { required, email } from "vee-validate/dist/rules";
  import {Option, Select, Tooltip} from "element-ui";
  import FgSelect from "@/components/Inputs/formGroupSelect";
  import FormGroupTextArea, {ImageUploader, PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
  import Editor from '@tinymce/tinymce-vue'

  extend("required", required);
  extend("email", email);

  export default {
    components: {
      FormGroupTextArea,
      [Option.name]: Option,
      [Select.name]: Select,
      [Tooltip.name]: Tooltip,
      FgSelect,
      LSwitch,
      PrimeUploader,
      ImageUploader,
      PrimeVideoUploader,
      'editor': Editor
    },
    props: {
      lang: {
        type: String,
        default: 'fr'
      },
      editMode: {
        type: Boolean,
        default: false
      },
      builderData:{},
      formData:{
        type: Object,
        default() {
          return {
            name: "",
            url: "",
            description: "",
            cost: "",
            category_id: '',
            first_installment_value: '',
            second_installment_value: '',
            installment_amount: '',
            pay_by_installments: false,
            training_sessions: [],
            is_active: true,
            hide_cost: false,
          }
        }
      },
    },
    data() {
      return {
        editorConfig: this.getEditorConfig(),
        editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
        imagesExtensions: this.getImagesExtensions(),
        videoExtensions: this.getVideoExtensions(),
        checkInstallmentsLabel: ''
      };
    },
    mounted() {
      if (!this.editMode){
        this.formData.category_id = 0;
      }
    },
    methods: {
      checkInstallments() {

        this.axios.post("training-cycles/check-installments", this.formData).then((response) => {
          this.checkInstallmentsLabel = response.data;
          this.loader.hide();
        }).catch((error) => {
          console.log(error)
          if (error.response.status === 404) {
            this.entityNotFoundError = true;
            this.$notify({
              message: this.$store.getters['languageMixin/getStringFromLanguage']("Something went wrong."),
              timeout: 2000,
              type: 'danger'
            });
            this.loader.hide();

          } else {
            console.error(error);
          }
        })
      },
      validate() {
        return this.$refs.form.validate().then(res => {
          if (res){
            this.$emit('submit-step', this.formData, this.lang)
          }else{
            this.$emit('on-validated', res)
          }
          return res
        });
      },
      generateSomeFields() {
        if (!this.editMode){
          let newUrl = '';
          if (this.formData.name) {
            newUrl = this.formData.name.replace(/\s+/g, "-").toLowerCase();
            newUrl = this.removeAccents(newUrl);
            newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
            newUrl = newUrl.replace(/([-]+)/g, "-");
          }

          this.formData.url = newUrl;
        }
      },
      changeCategory(){
        console.log(this.formData.category_id)
        this.$emit('categorySelected', this.formData.category_id);
      }
    }
  }
</script>
<style>
.team_list_items{
  border: 1px solid #cccc;
  padding: 10px 45px 30px 19px;
}
</style>
